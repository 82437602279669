export class ModificationModel {
  public major!: ModificationDataModel[];
  public minor!: ModificationDataModel[];
  public moderate!: ModificationDataModel[];

  public convert(dto: any): ModificationModel {
    if (dto.major) {
      this.major = dto.major.reduce((result: any, each: any) => {
        result.push(new ModificationDataModel().convert(each));
        return result;
      }, []);
    }
    if (dto.minor) {
      this.minor = dto.minor.reduce((result: any, each: any) => {
        result.push(new ModificationDataModel().convert(each));
        return result;
      }, []);
    }
    if (dto.moderate) {
      this.moderate = dto.moderate.reduce((result: any, each: any) => {
        result.push(new ModificationDataModel().convert(each));
        return result;
      }, []);
    }

    return this;
  }
}

export class ModificationDataModel {
  public id!: string;
  public title!: string;
  public icon!: string;
  public focus!: boolean;

  public convert(dto: any): ModificationDataModel {
    if (dto.id) {
      this.id = dto.id;
    }
    if (dto.name) {
      this.title = dto.name;
    }
    if (dto.iconUrl) {
      this.icon = dto.iconUrl;
    }

    this.focus = false;
    return this;
  }
}
