import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { FilterRequestPayload } from "@app/shared/interfaces/api-request.interface";
import { IResponseDTO } from "@app/shared/interfaces/response.interface";
import { environment } from "@environments/environment";
import { ArcRequestModel } from "./arc-request.model";
import { ArcRequestReqBody } from "./arc-request-req.model";

@Injectable({ providedIn: "root" })
export class ArcRequestService {
  protected http = inject(HttpClient);

  searchArchRequests<T = ArcRequestModel[]>(payload: FilterRequestPayload) {
    return this.http.post<IResponseDTO<T>>(
      `${environment.apiUrl}/requests/search`,
      payload,
      {
        withCredentials: true,
      }
    );
  }

  searchResidentArcRequests<T = ArcRequestModel[]>(
    payload: FilterRequestPayload
  ) {
    return this.http.post<IResponseDTO<T>>(
      `${environment.apiUrl}/resident/requests/search`,
      payload,
      {
        withCredentials: true,
      }
    );
  }

  createArcRequest<T = ArcRequestModel>(body: ArcRequestReqBody.CreateOne) {
    return this.http.post<T>(`${environment.apiUrl}/requests`, body);
  }

  patchArcRequest<T = ArcRequestModel>(
    id: string,
    body: ArcRequestReqBody.PatchOne
  ) {
    return this.http.patch<T>(`${environment.apiUrl}/requests/${id}`, body);
  }
}
