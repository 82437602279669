import { DestroyRef, inject, Injectable, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ASYNC_STATE, TAsyncState } from "@app/pages/task/+data-access";
import { ModificationService } from "@app/shared/services/modification.service";
import { RoamTableOptions } from "@app/utils";

@Injectable()
export class ArcApprovalStore {
  #destroyRef = inject(DestroyRef);
  #api = inject(ModificationService);

  asyncProgress = signal<TAsyncState>(ASYNC_STATE.IDLE);
  params = signal<RoamTableOptions>({
    pageSize: 10,
    pageIndex: 1,
    sortActive: "",
    sortDirection: "asc",
  });

  data = signal<any[]>([]); // TODO: define the model!
  total = signal<number>(0);

  getList(associationId: string, params: RoamTableOptions): void {
    this.asyncProgress.set("loading");
    const { pageIndex, pageSize } = params;
    //TODO Add Filter If needed
    this.#api
      .getModifications(associationId, pageSize, pageIndex)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: resp => {
          console.warn(resp);
          this.data.set(resp.data || []);
          this.total.set(resp.meta?.total || 0);
          this.asyncProgress.set("loaded");
          // this.selections.connect(resp.map(x => x.id));
        },
        error: () => {
          this.asyncProgress.set("error");
        },
      });
  }
}
